import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { ProjectSummary } from "../../components/projects/ProjectSummary";

const BrooksideSubdivision = () => (
  <Layout>
    <SEO
      title="Brown Owl Subdivision"
      image={"/brownowl.jpg"}
      description="SDCL were tasked with delivering the water main and retaining wall
      aspects of this sub-division."
    />

    <div className="row g-0">
      <div className="col-lg-6 d-flex align-items-center justify-content-center">
        <div className="content-box">
          <h1 className="section-heading section-heading--left">
            Brown Owl Subdivision
          </h1>
          <ProjectSummary
            name="Brown Owl Subdivision"
            type="Subdivision"
            location="Upper Hutt, Wellington "
            client="PCL Contracting Ltd"
          />
          <p>
            SDCL were tasked with delivering the water main and retaining wall
            aspects of this sub-division. The work involved the installation of
            405 meters of 63mm HDPE, 245m of 150mm uPVC Series 1 pipe, 41
            manifold connections, 3 hydrant connections on 150mm main, sluice
            and scour valve installations, electro fusion connections,
            commissioning and connections to existing asbestos mains.
            Construction of approx. 350LM of retaining walls ranging from 1m to
            2.8m high, with embedment depths from 2.8m to 4.5m.
          </p>
        </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center justify-content-center">
        <StaticImage
          aspectRatio={16 / 15}
          transformOptions={{ cropFocus: "center" }}
          objectPosition="50% 50%"
          src="../../images/brownowl.jpg"
          alt="Digger"
        />
      </div>
    </div>
  </Layout>
);

export default BrooksideSubdivision;
